<template>
  <section class="section-contacts">
    <div class="section-contacts__inner">
      <div class="container">
        <div class="section-contacts__content">
          <div class="section-contacts__info">
            <div class="section-contacts__title main__title">
              Наши контакты
            </div>
            <div class="section-contacts__text main__text">
              Оставьте свои контакты и мы вам<br>
              перезвоним
            </div>
            <div class="section-contacts__group main__text">
              <p class="section-contacts__phone">
                Телефон:<br>
                <a href="tel:+380681899783">+38 (068) 18 99 783</a>
              </p>
              <p class="section-contacts__email">
                Е-mail:<br>
                <a href="mailto:info@tez.zp.ua">info@tez.zp.ua</a>
              </p>
            </div>
            <form class="section-contacts__form">
              <div class="form__group">
                <input
                  class="form__group-input input--email"
                  type="text"
                  placeholder="Ваш e-mail"
                  required
                >
              </div>
              <div class="form__group">
                <input
                  class="form__group-input"
                  type="text"
                  placeholder="Ваше имя"
                  required
                >
              </div>
              <div class="form__group">
                <input
                  class="form__group-input"
                  type="tel"
                  placeholder="+38 (099) 99 99 999"
                  required
                >
              </div>
              <button
                class="btn btn--main"
                type="submit"
              >
                Отправить
              </button>
            </form>
          </div>
          <div class="section-contacts__map">
            <a
              href="https://goo.gl/maps/SAxMeAphJEKriKi38"
              target="_blank"
            >
              <img
                src="@/assets/images/map.jpeg"
                alt="Map"
                style="height: 100%; width: 100%; object-fit: cover"
              >
            </a>
            <div class="section-contacts__map-adress">
              <p class="main__text">
                69118, м. Запоріжжя, вул.<br>
                Привокзальна, 7-А
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-error form-error--hidden">
      <p>Введите данные корректно!</p>
      <img
        class="error-close"
        src="@/assets/images/close.svg"
        width="18px"
        height="18px"
        alt="close"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactMap",
  mounted(){      
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    const PHONE_REGEXP = /^[0-9 ()+-]+$/;
    const form = document.querySelector('.section-contacts__form');
    let INPUT_EMAIL = document.querySelector('.input--email');
    let INPUT_PHONE = document.querySelector('input[type="tel"]')
    let formError = document.querySelector('.form-error');

    let closeBtn = document.querySelector('.error-close');
    closeBtn.addEventListener('click', ()=>{
        formError.style.opacity=0;
    })

    function validateEmail(value) {
    return EMAIL_REGEXP.test(value);
    }
    function validatePhone(value) {
    return PHONE_REGEXP.test(value);
    }
    function updateEmail() {
      if (validateEmail(INPUT_EMAIL.value)) INPUT_EMAIL.style.borderColor = 'green';
      else INPUT_EMAIL.style.borderColor = 'red';
    }
    function updatePhone() {
      if (validatePhone(INPUT_PHONE.value)) INPUT_PHONE.style.borderColor = 'green';
      else INPUT_PHONE.style.borderColor = 'red';
    }

    form.addEventListener("submit", e=>{
      e.preventDefault();
      updateEmail() // проверяем имейл
      updatePhone() // проверяем телефон

      let resultPhone = validatePhone(INPUT_PHONE.value)
      let resultEmail = validateEmail(INPUT_EMAIL.value)
      if (resultEmail === false || resultPhone === false){
        formError.classList.replace('form-error--hidden', 'form-error--show');
        setTimeout(()=>{
          formError.classList.replace('form-error--show', 'form-error--hidden')
        }, 4000);
      }
      });
} 
};
</script>
